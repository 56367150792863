<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">客服管理</li>
            <li class="breadcrumb-item active" aria-current="page">FAQ管理</li>
        </ol>
        </nav>
        <hr class="w-100">
        <div class="col-12 row mb-3 mx-auto">
            <div class="col-12 accordion" id="accordion_filter">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="heading_filter">
                        <button class="accordion-button bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_filter" aria-expanded="false" aria-controls="collapse_filter">
                            搜尋條件
                        </button>
                    </h2>
                    <div id="collapse_filter" class="accordion-collapse show" aria-labelledby="heading_filter" data-bs-parent="#accordion_filter">
                        <div class="accordion-body col-12 row">
                            <div class="mb-3 col-lg-4">
                                <label for="faq_id" class="form-label">序號</label>
                                <input type="text" class="form-control" id="faq_id" v-model="display_query_data.faq_id">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="problem_type" class="form-label">問題類型</label>
                                <select id="problem_type" class="form-select" v-model="display_query_data.problem_type" @change="onChangeProblemType($event.target.value)">
                                    <option value="">全部</option>
                                    <template v-for="item in problem_type_option" :key="item">
                                        <option :value="item.value">{{ item.text }}</option>
                                    </template>
                                </select>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="problem_subtype" class="form-label">問題子類型</label>
                                <select id="problem_subtype" class="form-select" v-model="display_query_data.problem_subtype">
                                    <option value="">全部</option>
                                    <template v-for="item in problem_subtype_option" :key="item">
                                        <option :value="item.value">{{ item.text }}</option>
                                    </template>
                                </select>
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="question" class="form-label">常見問題</label>
                                <input type="text" class="form-control" id="question" v-model="display_query_data.question">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="content" class="form-label">答案</label>
                                <input type="text" class="form-control" id="content" v-model="display_query_data.content">
                            </div>
                            <div class="mb-3 col-lg-4">
                                <label for="problem_type" class="form-label">點閱次數排序</label>
                                <select id="problem_type" class="form-select" v-model="display_query_data.order_by">
                                    <option :value="''">不排序</option>
                                    <option :value="0">高</option>
                                    <option :value="1">低</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 row text-end mx-auto mb-3">
                            <div>
                                <button class="btn m-2 btn-primary" @click="search()">搜尋</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 row text-end mx-auto mb-3" v-if="role_list.service_faq_management.role.split(',')[1] === '1' || role_list.service_faq_management.role.split(',')[4] === '1'">
            <div>
                <router-link to="/FAQDetail"><button class="btn m-2 btn-success" v-if="role_list.service_faq_management.role.split(',')[1] === '1'">新增</button></router-link>
                <button class="btn m-2 btn-secondary" v-if="role_list.service_faq_management.role.split(',')[4] === '1'" :disabled="records.length === 0" v-on:click="export_data()">匯出</button>
            </div>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <div class="table-responsive container-lg col-12">
                <table class="table table-striped table-hover text-center align-middle">
                    <thead class="table-dark">
                        <tr>
                            <th class="col-1">序號</th>
                            <th class="col-1">問題類型</th>
                            <th class="col-1">問題子類型</th>
                            <th class="col-1">常見問題</th>
                            <th class="col-2">答案</th>
                            <th class="col-1">點閱次數</th>
                            <th class="col-2">建立時間</th>
                            <th class="col-2">更新時間</th>
                            <th class="col-1">功能</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in records" :key="item">
                            <td>{{ item.faq_id }}</td>
                            <td>{{ item.problem_type }}</td>
                            <td>{{ item.problem_subtype }}</td>
                            <td>{{ item.question }}</td>
                            <td style="
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                max-width: 100px;">
                                {{ item.content }}
                            </td>
                            <td>{{ item.count }}</td>
                            <td>{{ item.create_time }}</td>
                            <td>{{ item.update_time }}</td>
                            <td>
                                <span>
                                    <router-link :to="'/FAQDetailView/'+ item.faq_id" v-if="role_list.service_faq_management.role.split(',')[0] === '1'">
                                        <button class="btn m-2 btn-primary">檢視</button>
                                    </router-link>
                                    <router-link :to="'/FAQDetail/'+ item.faq_id" v-if="role_list.service_faq_management.role.split(',')[2] === '1'">
                                        <button class="btn m-2 btn-warning">編輯</button>
                                    </router-link>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                        <a class="page-link" aria-label="<<"
                            @click.prevent="onChangePage(1)">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                        <a class="page-link" aria-label="<"
                            @click.prevent="onChangePage(current_page - 1)">
                            <span aria-hidden="true">&lsaquo;</span>
                        </a>
                    </li>
                    <li class="page-item" v-for="page in links" :key="page" 
                        :class="{'active': page.active, 'disabled': page.label === '...'}" v-bind:style="{'cursor': page.active ? null : page.label === '...' ? null : 'pointer'}">
                        <a class="page-link" @click.prevent="onChangePage(page.label)">{{ page.label }}</a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                        <a class="page-link" aria-label=">"
                            @click.prevent="onChangePage(current_page + 1)">
                            <span aria-hidden="true">&rsaquo;</span>
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                        <a class="page-link" aria-label=">>"
                            @click.prevent="onChangePage(last_page)">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-12 row mx-auto mb-3">
            <div class="input-group justify-content-center">
                <span class="text-vertical-center">前往第</span>
                <input type="number" class="form-control m-2" style="flex: 0 0 auto; width: auto;" id="current_page" v-model="current_page" :min="1" :max="9999999999">
                <span class="text-vertical-center">頁</span>
                <div>  
                    <button class="btn m-2 btn-primary" @click.prevent="onChangePage(current_page)">前往</button>
                </div>
            </div>
        </div>
        <div class="mb-3"></div>
    </div>
</template>

<script>
import {
    get_faq_list,
    export_faq_list
} from '../../api/faq';
import { mapActions, mapGetters } from 'vuex';
import {
    get_problem_type_option,
    get_problem_subtype_option
} from '../../api/data';
import FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import moment from 'moment';
import Swal from 'sweetalert2';

export default {
    components: {
    },
    data() {
        return {
            last_page: 1,
            current_page: 1,
            links: [{
                label: "1",
                active: true,
            }],
            records: [],
            problem_type_option: [],
            display_query_data: {
                faq_id: "",
                problem_type: "",
                question: "",
                content: "",
                order_by: "",
                problem_subtype: "",
            },
            query_data: {
                faq_id: "",
                problem_type: "",
                question: "",
                content: "",
                order_by: "",
                problem_subtype: "",
            },
            page: 1,
            export_query_data: {
                faq_id: "",
                problem_type: "",
                question: "",
                content: "",
                order_by: "",
                problem_subtype: "",
            },
            problem_subtype_option: [],
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search(page = 1, page_change = false) {
            if (page_change === false) {
                this.query_data = JSON.parse(JSON.stringify(this.display_query_data));
            }
            this.page = page;
            this.export_query_data = JSON.parse(JSON.stringify(this.query_data));
            get_faq_list({page: page, query_data: this.query_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'Index' });
                } else {
                    if (this.page === 1
                        && this.query_data.faq_id === ""
                        && this.query_data.problem_type === ""
                        && this.query_data.question === ""
                        && this.query_data.content === ""
                        && this.query_data.order_by === ""
                        && this.$route.fullPath === this.$route.path
                        && this.query_data.problem_subtype === ""
                    ) {
                        this.records = response.data.response_data.data;
                        this.links = response.data.response_data.links;
                        this.current_page = response.data.response_data.current_page;
                        this.last_page = response.data.response_data.last_page;
                    } else {
                        this.$router.push({
                            name: 'FAQList', 
                            query: {
                                page: this.page,
                                faq_id: this.query_data.faq_id,
                                problem_type: this.query_data.problem_type,
                                question: this.query_data.question,
                                content: this.query_data.content,
                                order_by: this.query_data.order_by,
                                problem_subtype: this.query_data.problem_subtype,
                            }
                        });
                        this.records = response.data.response_data.data;
                        this.links = response.data.response_data.links;
                        this.current_page = response.data.response_data.current_page;
                        this.last_page = response.data.response_data.last_page;
                    }  
                }
            })
        },
        export_data() {
            export_faq_list({query_data: this.export_query_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'Index' });
                } else {
                    const data = response.data.response_data

                    const workbook = new ExcelJS.Workbook()
                    const sheet = workbook.addWorksheet('FAQ清單列表')

                    // 預設字體
                    const font = { name: 'Microsoft JhengHei', size: 12}

                    // 凍結表格
                    sheet.views = [
                        {state: 'frozen', ySplit: 2}
                    ];

                    // 預設欄位寬度
                    for (let i = 1; i <= 9 ; i++) {
                        // 預設欄位寬度
                        if (i === 4) {
                            sheet.getColumn(i).width = 45;
                        } else if (i === 5){
                            sheet.getColumn(i).width = 90;
                        } else if (i === 9){
                            sheet.getColumn(i).width = 30;
                        }  else {
                            sheet.getColumn(i).width = 15;
                        }
                        // 預設字體樣式
                        sheet.getColumn(i).font = font;
                    }

                    sheet.getCell('A1').value = "FAQ清單列表"
                    sheet.getCell('A1').font = { name: 'Microsoft JhengHei', family: 4, size: 16, bold: true }
                    sheet.getCell('F1').value = '製表日期：' + moment(new Date()).format('yyyy/MM/DD').toString()
                    sheet.getCell('F1').font = { name: 'Microsoft JhengHei', bold: true }
                    sheet.getRow(1).height = 40
                    sheet.getRow(2).height = 40

                    // 置中 & 自動換列
                    sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
                    sheet.getCell('F1').alignment = { vertical: 'middle', horizontal: 'center' };
                    sheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

                    // 合併表格
                    sheet.mergeCells('A1:E1');
                    sheet.mergeCells('F1:G1');

                    // 填寫預設欄位
                    for (let i = 1 ; i < 10; i++) {
                    sheet.getRow(2).getCell(i).border = { top: { style: 'medium' }, left: { style: 'medium' },
                        bottom: { style: 'medium' }, right: { style: 'medium' } };
                    }
                    sheet.getRow(2).getCell(1).value = '序號';
                    sheet.getRow(2).getCell(2).value = '問題類型';
                    sheet.getRow(2).getCell(3).value = '問題子類型';
                    sheet.getRow(2).getCell(4).value = '常見問題';
                    sheet.getRow(2).getCell(5).value = '答案';

                    sheet.getRow(2).getCell(6).value = '點閱次數';
                    sheet.getRow(2).getCell(7).value = '建立時間';
                    sheet.getRow(2).getCell(8).value = '更新時間';
                    sheet.getRow(2).getCell(9).value = '最後更新使用者';

                    // 填寫資料
                    for (let i = 0; i < data.length; i++) {
                        sheet.getRow(i + 3).font = { name: 'Microsoft JhengHei'};
                        sheet.getRow(i + 3).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

                        sheet.getRow(i + 3).getCell(1).value = data[i].faq_id;
                        sheet.getRow(i + 3).getCell(2).value = data[i].problem_type;
                        sheet.getRow(i + 3).getCell(3).value = data[i].problem_subtype;
                        sheet.getRow(i + 3).getCell(4).value = data[i].question;
                        sheet.getRow(i + 3).getCell(5).value = data[i].content;

                        sheet.getRow(i + 3).getCell(6).value = data[i].count;
                        sheet.getRow(i + 3).getCell(7).value = data[i].create_time;
                        sheet.getRow(i + 3).getCell(8).value = data[i].update_time;
                        sheet.getRow(i + 3).getCell(9).value = data[i].update_user;
                    }
                    
                    workbook.xlsx.writeBuffer().then(data => {
                        const blob = new Blob([data], {type: 'application/octet-stream'})
                        FileSaver.saveAs(blob, "FAQ清單列表_" + moment(new Date()).format('YYYYMMDD_HHmmss').toString() +".xlsx")
                    })
                }
            })
        },
        onChangeProblemType(problem_type, init = false) {
            if (init === false) {
                this.query_data.problem_subtype = "";
            }
            get_problem_subtype_option({problem_type: problem_type}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    this.problem_subtype_option = response.data.response_data["TW"];
                }
            })
        },
        onChangePage(page) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })
            if (page > this.last_page || page <= 0) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，無指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else if (page == this.page) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，已於指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else {
                this.search(page, true);
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        if (this.$route.query.page !== undefined) {
            if (this.$route.query.faq_id !== undefined) {
                this.display_query_data.faq_id = this.$route.query.faq_id;
            }
            if (this.$route.query.problem_type !== undefined) {
                this.display_query_data.problem_type = this.$route.query.problem_type;
                this.onChangeProblemType(this.$route.query.problem_type, true);
            }
            if (this.$route.query.question !== undefined) {
                this.display_query_data.question = this.$route.query.question;
            }
            if (this.$route.query.content !== undefined) {
                this.display_query_data.content = this.$route.query.content;
            }
            if (this.$route.query.order_by !== undefined) {
                this.display_query_data.order_by = this.$route.query.order_by;
            }
            if (this.$route.query.problem_subtype !== undefined) {
                this.display_query_data.problem_subtype = this.$route.query.problem_subtype;
            }
            this.export_query_data = JSON.parse(JSON.stringify(this.display_query_data));
            if (this.$route.query.page == 1) {
                this.search(this.$route.query.page, false);
            } else {
                this.search(this.$route.query.page);
            }
        } else {
            this.search(this.page);
        }
        get_problem_type_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.problem_type_option = response.data.response_data["TW"];
            }
        })
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
    }
};
</script>